<template>
  <div class="notice">
     <top-bar :title="'群团聚力'" :left="true"></top-bar>
    <div class="workGoal">
      <div v-for="item in infoList" :key="item.id">
        <work-item v-if="item.id % 4 == 1" @click.native="union(item.name, item.id)" :title="item.name" titlEng="union" :imgurl="require('@/assets/img/union.png')" style="
            background-image: linear-gradient(to right, #9487fd, #7a68f2);
            border-radius: 10px;
          " />
        <work-item v-if="item.id % 4 == 2" @click.native="union(item.name, item.id)" :title="item.name" titlEng="Communist Youth League" :imgurl="require('@/assets/img/comYou.png')" style="
            background-image: linear-gradient(to right, #6fa4ff, #367ef4);
            border-radius: 10px;
          " />
        <work-item v-if="item.id % 4 == 3" @click.native="union(item.name, item.id)" :title="item.name" titlEng="Women's Federation" :imgurl="require('@/assets/img/women.png')" style="
            background-image: linear-gradient(to right, #fd7b79, #f64744);
            border-radius: 10px;
          " />

        <work-item v-if="item.id % 4 == 0" @click.native="union(item.name, item.id)" :title="item.name" titlEng="AST" :imgurl="require('@/assets/img/AST.png')" style="
            background-image: linear-gradient(to right, #feb278, #fd8730);
            border-radius: 10px;
          " />
      </div>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import workItem from "../workGoals/workItem.vue";

export default {
  components: {
    topBar,
    workItem,
  },
  data() {
    return {
      infoList: [],
    };
  },
  methods: {
    getDatalist() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqalliance/list"),
        method: "post",
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.infoList = data.page.list;
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    union(title, id) {
      this.$router.push({
        name: "gridUnion",
        query: { title, title, id: id },
      });
    },
  },
  mounted() {
    this.getDatalist();
  },
};
</script>

<style scoped>
.notice {
  width: 100vw;
  height: 100vh;
}
.workGoal {
  width: 100vw;
  height: 100vh;
  background-color: #f6f5f3;
}
</style>
